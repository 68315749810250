import { inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { KioskApiHttpService } from '@abbadox-monorepo/kiosk-core-http-client';

@Injectable({
  providedIn: 'root',
})
export class BillingHttpService {
  private readonly kioskApiHttpService = inject(KioskApiHttpService);

  submitPayment(): Observable<any> {
    return of(true);
  }
}
